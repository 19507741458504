<template>
  <div class="infinite">
    <slot />
    <div ref="infiniteDom">
      <div class="loading" v-if="loading">正在加载中...</div>
      <div class="empty" v-if="finished">已没有更多数据</div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  name: 'infinite-loading',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    finished: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const infiniteDom = ref(null)
    let observer = null

    onMounted(() => {
      observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if(isIntersecting) {
          if(!props.loading && !props.finished) {
            emit('infinite')
          }
        }
      }, {
        threshold: 0
      })

      observer.observe(infiniteDom.value)
    })

    onUnmounted(() => {
      observer.disconnect()
    })

    return {
      infiniteDom
    }
  }
}
</script>

<style lang="less" scoped>
.infinite {
  .loading, .empty {
    color: #999;
    text-align: center;
    line-height: 50px;
  }
}
</style>
