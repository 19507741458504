import request from '@/utils/request'

// 商品列表
export const getProducts = params => {
  return request({
    url: '/pc/get_products',
    params
  })
}

// 商品详情
export const getProductDetail = params => {
  return request({
    url: '/pc/detail',
    params
  })
}
